
interface Source {
    src: string;
    x?: number;
    y?: number;
    rotate?: number;
}
interface Image extends Source {
    img: HTMLImageElement;
}

// Return Promise
const mergeImages = (sources: Source[] = []): Promise<string> => new Promise(resolve => {
    const canvas = window.document.createElement('canvas');
    const Image = window.Image;

    // Load sources
    const images: Promise<Image>[] = sources.map(source => new Promise((resolve, reject) => {

        // Resolve source and img when loaded
        const img = new Image();
        img.crossOrigin = '*';
        img.onerror = () => reject(new Error('Couldn\'t load image'));
        img.onload = () => resolve(Object.assign({}, source, { img }));
        img.src = source.src;
    }));

    // Get canvas context
    const ctx = canvas.getContext('2d');

    // When sources have loaded
    resolve(Promise.all(images)
        .then((images: Image[]) => {
            // Set canvas dimensions
            canvas.width = 2500;
            canvas.height = 2500;

            // Draw images to canvas

            images.forEach(image => {
                ctx!.globalAlpha = 1;
                if (image.rotate) {
                    ctx!.translate(1250, 1250);
                    ctx!.rotate(image.rotate * Math.PI / 180)
                    ctx!.translate(-1250, -1250);
                }
                ctx!.drawImage(image.img, image.x || 0, image.y || 0, 2500, 2500);
                if (image.rotate) {
                    ctx!.translate(1250, 1250);
                    ctx!.rotate(image.rotate * Math.PI / 180)
                    ctx!.translate(-1250, -1250);
                }
            });




            // Resolve all other data URIs sync
            return canvas.toDataURL('image/png', 1);
        }));
});

export default mergeImages;