import React, { useEffect, useRef, useState } from "react";

import "./App.css";

import faq from "./images/guide.pdf";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/system/Container";
import CssBaseline from "@mui/material/CssBaseline";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import DownloadIcon from "@mui/icons-material/Download";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TwitterIcon from "@mui/icons-material/Twitter";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CherryBomb from "./images/CherryBomb-Regular.ttf";
import Welcome from "./images/welcome.png";

import OptionsTabs from "./components/tabs/Tabs";
import mergeImages from "./utils/func.utils";

interface Dictionary {
  [index: string]: string;
}
function App() {
  const rotateRef = useRef(false);
  const [tokenID, setTokenID] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ogImg, setOgImg] = useState("");
  const [bgImg, setBgImg] = useState("");
  const [img, setImg] = useState(Welcome);
  const [mug, setMug] = useState("");
  const [hand, setHand] = useState("");
  const [background, setBackground] = useState("");
  const [backgroundLoading, setBackgroundLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: "CherryBomb, Arial",
      body1: {
        color: "#ffc300",
      },
    },
    palette: {
      secondary: {
        main: "#5edbdf",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "#ffffff",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "#ffc300",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'CherryBomb';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('CherryBomb'), local('CherryBomb-Regular'), url(${CherryBomb}) format('woff2');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
    },
  });

  useEffect(() => {
    if (ogImg) {
      setLoading(true);
      const rotate = { rotate: 0 };
      if (rotateRef.current) rotate.rotate = 180;
      const img = [{ src: ogImg }];
      if (background) {
        if (bgImg) img[0] = { src: bgImg };
        if (background !== "None") img.unshift({ src: background });
      }
      mergeImages([
        ...img,
        ...(mug ? [{ src: mug, ...rotate }] : []),
        ...(hand ? [{ src: hand, ...rotate }] : []),
      ])
        .then((b64) => setImg(b64))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [bgImg, background, ogImg, mug, hand]);

  const handsContext = require.context(
    "./images/hands",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const hands: Dictionary = {};
  handsContext
    .keys()
    .forEach(
      (item) =>
        (hands[item.replaceAll(/(\.\/|\.(png|jpe?g|svg))/g, "")] =
          handsContext(item))
    );
  const shakaContext = require.context(
    "./images/shaka",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const shaka: Dictionary = {};
  shakaContext
    .keys()
    .forEach(
      (item) =>
        (shaka[item.replaceAll(/(\.\/|\.(png|jpe?g|svg))/g, "")] =
          shakaContext(item))
    );
  const oneOoneContext = require.context(
    "./images/oneOone",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const oneOone: Dictionary = {};
  oneOoneContext
    .keys()
    .forEach(
      (item) =>
        (oneOone[item.replaceAll(/(\.\/|\.(png|jpe?g|svg))/g, "")] =
          oneOoneContext(item))
    );
    console.log(oneOone)
  const mugsContext = require.context(
    "./images/mugs",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const mugs = mugsContext.keys().map(mugsContext) as string[];
  const sassetsContext = require.context(
    "./images/sassets",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const sassets = sassetsContext.keys().map(sassetsContext) as string[];
  const backgroundsContext = require.context(
    "./images/backgrounds",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const backgrounds = backgroundsContext
    .keys()
    .map(backgroundsContext) as string[];
  async function postImage(imageFile: Blob, tokenID: string) {
    setBackgroundLoading(true);
    console.log(oneOone[tokenID])
    if (tokenID in oneOone) {
      setBgImg(oneOone[tokenID]);
      console.log("oneOone");
      setBackgroundLoading(false);
      return;
    }
    console.log(tokenID);
    const formData = new FormData();
    formData.append("file", imageFile);
    try {
      const response = await fetch(
        "https://bg_remove-1-t0575285.deta.app/removebg",
        {
          method: "POST",
          body: formData,
          mode: "cors",
        }
      );
      const processedImage = await response.blob();
      setBgImg(URL.createObjectURL(processedImage));
    } catch (error) {
      console.log(error);
    }
    setBackgroundLoading(false);
    // Do something with the processed image, e.g. display it on the page
  }
  const handleToken = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);
    if (tokenID === "201") rotateRef.current = true;
    else rotateRef.current = false;

    fetch(
      `https://ipfs.io/ipfs/QmWMtPxodFVjBhALGrSbk1kVbBKYwXcxAWpHTpE2oK8X8k/${tokenID}`
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        const fur = res.attributes.find(
          (el: Dictionary) => el.trait_type === "Fur"
        );
        if (fur) {
          setHand(hands[fur.value]);
        } else {
          var keys = Object.keys(shaka);
          setHand(shaka[keys[(keys.length * Math.random()) << 0]]);
        }
      });
    setMug(``);
    setBackground("");
    setBgImg("");
    fetch(
      `https://ipfs.io/ipfs/QmXbhJxe6rcKWQvTMBSpqEE7ia1CFeWGjQJmHTxWyF3jiU/${tokenID}.png`
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.blob();
      })
      .then((res) => {
        setOgImg(URL.createObjectURL(res));
        postImage(res, tokenID);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const download = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = img;
    a.download = "img.png";
    a.click();
    handleClose();
  };
  const guide = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = faq;
    a.download = "img.png";
    a.click();
    handleClose();
  };

  const randomizer = () => {
    setMug(mugs[~~(Math.random() * mugs.length)]);
    setBackground(backgrounds[~~(Math.random() * backgrounds.length)]);
    const combinedValues = [...Object.values(shaka), ...Object.values(hand)];
    const randomValue = combinedValues[~~(Math.random() * combinedValues.length)];
    setHand(randomValue);
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          textAlign={"center"}
          sx={{ color: "#5edbdf", fontSize: "4rem" }}
          variant="h1"
        >
          Shredding Sassy
        </Typography>
        <Grid
          sx={{ mt: { xs: "0.4rem" } }}
          container
          rowSpacing={{ xs: 2, md: 0 }}
          columnSpacing={{ xs: 0, md: 2 }}
          alignItems="start"
          justifyContent="center"
          boxShadow={5}
          borderRadius={5}
          padding={2}
        >
          <Grid item xs={12} md={6}>
            <img
              className={
                loading || (backgroundLoading && background)
                  ? "loading img"
                  : "done img"
              }
              width={"100%"}
              alt="avatar"
              style={{
                borderRadius: "5px",
                transition: "all 0.5s linear",
                transform: "rotateY(0)",
                animationFillMode: "both",
                position: "sticky",
                top: "1rem",
              }}
              src={img}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleToken}>
              <Stack direction="row" spacing={2}>
                <TextField
                  helperText={error ? "Token ID is invalid" : null}
                  error={error}
                  value={tokenID}
                  onChange={(e) => setTokenID(e.target.value)}
                  id="outlined-basic"
                  label="Token ID"
                  color="secondary"
                  variant="outlined"
                />
                <Button type="submit" size="large" variant="contained">
                  Submit
                </Button>
              </Stack>
            </form>

            <OptionsTabs
              mugs={mugs}
              mug={mug}
              setMug={setMug}
              hands={Object.values(hands)}
              hand={hand}
              setHand={setHand}
              shakas={Object.values(shaka)}
              backgrounds={backgrounds}
              background={background}
              setBackground={setBackground}
              sassets={sassets}
            />
          </Grid>
        </Grid>

        <Typography sx={{ color: "black", mt: "0.4rem" }} textAlign={"center"}>
          Copyright 2023 PharoBox, All Right Reserved
          <a href="https://twitter.com/PharoBox">
            <TwitterIcon color="primary" />
          </a>
        </Typography>

        <SpeedDial
          ariaLabel="SpeedDial"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          <SpeedDialAction
            key={"Download"}
            icon={<DownloadIcon />}
            tooltipTitle={"Download"}
            tooltipOpen
            onClick={download}
          />
          <SpeedDialAction
            key={"Randomize"}
            icon={<ShuffleIcon />}
            tooltipTitle={"Randomize"}
            tooltipOpen
            onClick={randomizer}
          />
          <SpeedDialAction
            key={"Guide"}
            icon={<ContactSupportIcon />}
            tooltipTitle={"Guide"}
            tooltipOpen
            onClick={guide}
          />
        </SpeedDial>
      </Container>
    </ThemeProvider>
  );
}

export default App;
