import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import IconButton from "@mui/material/IconButton";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabsProps {
  mug: string;
  setMug: Function;
  mugs: string[];
  background: string;
  backgrounds: string[];
  setBackground: Function;
  hand: string;
  setHand: Function;
  hands: string[];
  shakas: string[];
  sassets: string[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      <Box>{children}</Box>
    </div>
  );
}

function sxList(val: string, curr: string) {
  return {
    ...(val === curr ? { border: "2px solid #00b4d8" } : null),
    ":hover": { cursor: "pointer", border: "2px solid #023e8a" },
    borderRadius: "10px",
    overflow: "hidden",
  };
}

export default function OptionsTabs({
  shakas,
  mug,
  setMug,
  mugs,
  hand,
  setHand,
  hands,
  background,
  backgrounds,
  setBackground,
  sassets,
}: TabsProps) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setBackground(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs"
        >
          <Tab label="Hands" id="hand-tab" aria-controls="hand-tab" />
          <Tab label="Shakas" id="shaka-tab" aria-controls="shaka-tab" />
          <Tab label="Mugs" id="mug-tab" aria-controls="mug-tab" />
          <Tab label="Backgrounds" id="bg-tab" aria-controls="bg-tab" />
          <Tab label="Sassets" id="sassets-tab" aria-controls="sassets-tab" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ImageList gap={8} cols={4} rowHeight={90}>
          {hands.map((image: string, i) => (
            <ImageListItem
              sx={sxList(hand, image)}
              onClick={() => {
                if (hand === image) setHand("");
                else setHand(image);
              }}
              key={i}
            >
              <img
                loading="lazy"
                style={{ marginTop: "-80%", marginLeft: "-5%" }}
                src={image}
                alt={`${i}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ImageList gap={8} cols={4} rowHeight={90}>
          {shakas.map((image: string, i) => (
            <ImageListItem
              sx={sxList(hand, image)}
              onClick={() => {
                if (hand === image) setHand("");
                else setHand(image);
              }}
              key={i}
            >
              <img
                loading="lazy"
                style={{ marginTop: "-80%", marginLeft: "-5%" }}
                src={image}
                alt={`${i}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ImageList gap={8} cols={4} rowHeight={70}>
          {mugs.map((image: string, i) => (
            <ImageListItem
              sx={sxList(mug, image)}
              onClick={() => {
                if (mug === image) setMug("");
                else setMug(image);
              }}
              key={i}
            >
              <img
                loading="lazy"
                style={{ marginTop: "-80%", marginLeft: "-30%" }}
                src={image}
                alt={`${i}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ImageList gap={8} cols={4} rowHeight={70}>
          <ImageListItem
            sx={{
              ...sxList(background, "None"),
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              if (background === "None") setBackground("");
              else setBackground("None");
            }}
            key="None"
          >
            <NotInterestedIcon fontSize="large" />
          </ImageListItem>
          <ImageListItem
            sx={{
              ...sxList(background, "upload"),
              justifyContent: "center",
              alignItems: "center",
            }}
            key="upload"
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleFileChange}
              />
              <PhotoCamera sx={{ color: "#fff" }} />
            </IconButton>
          </ImageListItem>
          {backgrounds.map((image: string, i) => (
            <ImageListItem
              sx={sxList(background, image)}
              onClick={() => {
                if (background === image) setBackground("");
                else setBackground(image);
              }}
              key={i}
            >
              <img loading="lazy" src={image} alt={`${i}`} />
            </ImageListItem>
          ))}
        </ImageList>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ImageList gap={8} cols={4} rowHeight={70}>
          {sassets.map((image: string, i) => (
            <ImageListItem
              sx={sxList(mug, image)}
              onClick={() => {
                if (mug === image) setMug("");
                else setMug(image);
              }}
              key={i}
            >
              <img
                loading="lazy"
                style={{ marginTop: "-80%", marginLeft: "-30%" }}
                src={image}
                alt={`${i}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </TabPanel>
    </Box>
  );
}
